import React from "react";
import styled from "@emotion/styled";
import LinkFile from "../../files/sample.pdf";
import { Link } from "gatsby";
import { Color } from "../../utils/color";

export const ProductItemLeadWrapper = () => {
  return (
    <SWrapper>
      <STitle>
        <p>カテゴリ名</p>
        <h3>製品A</h3>
      </STitle>
      <SDetail>
        サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
      </SDetail>
      <SButtonWrap>
        <SButton>
          <a
            href={LinkFile}
            data-color="light"
            target="_blank"
            rel="noreferrer"
          >
            <span className="material-icons-outlined">file_download</span>
            <span>資料のダウンロード</span>
          </a>
        </SButton>
        <SButton>
          <Link data-color="primary" to="/contact/">
            <span className="material-icons-outlined">shopping_bag</span>
            <span>この製品をお問い合わせ</span>
          </Link>
        </SButton>
      </SButtonWrap>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  width: 48%;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    width: 100%;
    margin-top: 2rem;
  }
`;

const STitle = styled.div`
  p {
    line-height: 1;
    font-weight: 500;
    font-size: 0.8rem;
  }
  h3 {
    line-height: 1;
    margin-top: 0.5rem;
  }
`;

const SButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const SDetail = styled.p`
  margin-top: 2rem;
`;

const SButton = styled.div`
  margin-top: 0.5rem;
  a {
    text-decoration: none;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 8px;
    /* hover */
    @media screen and (min-width: 1025px) {
      &:hover {
        opacity: 0.7;
      }
    }
    span {
      line-height: 1;
      color: ${Color.primary};
      &:first-of-type {
        margin-right: 1rem;
        font-size: 1.5rem;
        font-weight: 300;
      }
      &:last-of-type {
        font-size: 0.9rem;
        font-weight: 700;
      }
    }
    &:first-of-type {
      margin-top: 0;
    }
    &[data-color="primary"] {
      border: 1px solid ${Color.primary};
      background-color: ${Color.primary};
      span {
        color: ${Color.light};
      }
    }
    &[data-color="light"] {
      border: 1px solid ${Color.primary};
      background-color: #fff;
      span {
        color: ${Color.primary};
      }
    }
    /* ---------- */
    /* tb */
    @media screen and (max-width: 1023px) {
      &:active {
        opacity: 0.7;
      }
    }
  }
`;
