import React from "react";
import styled from "@emotion/styled";

import { DetailHeading } from "../heading/detailHeading";

export const ProductItemDetailWrapper = () => {
  return (
    <SWrapper>
      <SDetail>
        <DetailHeading heading="この製品について" />
        <p>
          サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
        </p>
      </SDetail>
      <SDetail>
        <DetailHeading heading="製品の特徴" />
        <p>
          サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
        </p>
      </SDetail>
      <SDetail>
        <DetailHeading heading="製品仕様" />
        <ul>
          <li>仕様：サンプルテキスト</li>
          <li>仕様：サンプルテキスト</li>
          <li>仕様：サンプルテキスト</li>
          <li>仕様：サンプルテキスト</li>
          <li>仕様：サンプルテキスト</li>
          <li>仕様：サンプルテキスト</li>
        </ul>
      </SDetail>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    width: 100%;
    margin-top: 2rem;
  }
`;

const SDetail = styled.div`
  margin-top: 2rem;
  p,
  ul {
    font-size: 0.9rem;
    list-style: none;
  }
`;
