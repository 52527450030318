import React from "react";
import { Link } from "gatsby";

import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs";
import { Layout } from "../../../components/layout/layout";
import { ProductItemSection } from "../../../components/section/productItemSection";
import { ProductCategorySection } from "../../../components/section/productCategorySection";

const Item001Page = () => {
  return (
    <Layout
      title="製品001 | LIME"
      jpUrl="/product/category/item001/"
      enUrl="/en/product/category/item001/"
    >
      <Breadcrumbs>
        <Link to="/">ホーム</Link>
        <span>/</span>
        <Link to="/product/">製品案内</Link>
        <span>/</span>
        <Link to="/product/category/">カテゴリ</Link>
        <span>/</span>
        <Link to="/product/category/item001/">製品001</Link>
        <span>/</span>
      </Breadcrumbs>
      <ProductItemSection />
      <ProductCategorySection />
    </Layout>
  );
};

export default Item001Page;
