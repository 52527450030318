import React from "react";
import styled from "@emotion/styled";

import { ProductItemImgWrapper } from "../wrapper/productItemImgWrapper";
import { ProductItemLeadWrapper } from "../wrapper/productItemLeadWrapper";
import { ProductItemDetailWrapper } from "../wrapper/productItemDetailWrapper";

import { ContainerWrapper } from "../wrapper/containerWrapper";

export const ProductItemSection = () => {
  return (
    <SSection>
      <ContainerWrapper>
        <ProductItemImgWrapper />
        <ProductItemLeadWrapper />
        <ProductItemDetailWrapper />
      </ContainerWrapper>
    </SSection>
  );
};
const SSection = styled.section`
  padding: 4rem 0;
  .container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
    width: 100%;
    .container {
      flex-direction: column;
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 599px) {
  }
`;
