import React from "react";
import { useState } from "react";
import styled from "@emotion/styled";

import ImgProductItem001 from "../../images/product_item_001.jpg";
import ImgProductItem002 from "../../images/product_item_002.jpg";
import ImgProductItem003 from "../../images/product_item_003.jpg";
import ImgProductItem004 from "../../images/product_item_004.jpg";
import { ImgChangeBtn } from "../btn/imgChangeBtn";

export const ProductItemImgWrapper = () => {
  const [isShow, setIsShow] = useState(1);
  const onClickHandler = (e) => {
    const target = e.currentTarget.dataset.number;
    setIsShow(target);
  };
  return (
    <SWrapper>
      <SImg>
        <img data-img={isShow} src={ImgProductItem001} alt="product" />
        <img data-img={isShow} src={ImgProductItem002} alt="product" />
        <img data-img={isShow} src={ImgProductItem003} alt="product" />
        <img data-img={isShow} src={ImgProductItem004} alt="product" />
      </SImg>
      <SBtn>
        <SButton onClick={onClickHandler} data-number="1">
          <ImgChangeBtn img={ImgProductItem001} />
        </SButton>
        <SButton onClick={onClickHandler} data-number="2">
          <ImgChangeBtn img={ImgProductItem002} />
        </SButton>
        <SButton onClick={onClickHandler} data-number="3">
          <ImgChangeBtn img={ImgProductItem003} />
        </SButton>
        <SButton onClick={onClickHandler} data-number="4">
          <ImgChangeBtn img={ImgProductItem004} />
        </SButton>
      </SBtn>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const SBtn = styled.div`
  display: inline-flex;
`;

const SButton = styled.button`
  width: 64px;
  height: 64px;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  /* hover */
  @media screen and (min-width: 1025px) {
    &:hover {
      opacity: 0.7;
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    &:active {
      opacity: 0.7;
    }
  }
`;

const SImg = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    opacity: 0;
    &[data-img="1"] {
      &:nth-of-type(1) {
        opacity: 1;
      }
    }
    &[data-img="2"] {
      &:nth-of-type(2) {
        opacity: 1;
      }
    }
    &[data-img="3"] {
      &:nth-of-type(3) {
        opacity: 1;
      }
    }
    &[data-img="4"] {
      &:nth-of-type(4) {
        opacity: 1;
      }
    }
  }
`;
